<template>
    <div class="container">



        <h2 class="head2 " style="margin-top:-0px;display:block;">{{Lang.get('link7')}}</h2>

        <div class="mt-5 mb-5" style="padding-top:30px;">

        </div>
        <div v-if="Lang.SelectedLanguage=='tr'">
            <div class="cap" style="margin-top:-100px;">
                <carousel :perPage="perPage" :autoplay="autoplay" :autoplayTimeout="autoplayTimeout" :loop="autoplay"  paginationColor="#ffffff" paginationActiveColor="#e91d2d">
                    <slide>
                        <div>
                            <div v-html="Lang.get('text7')" class="text-red bold Truculenta text-32" style="max-width:760px;margin:auto;"></div>
                            <img src="../assets/uretim-kapasite.png" class="img-fluid" style="margin-top:20px;margin-bottom:20px;" />
                            <div style="max-width:740px;margin:auto;">
                                <h3 class="text-red bold Truculenta text-37">Bisküvi</h3>
                                80 bin m²’si kapalı olmak üzere toplam 110 bin m² alan üzerine kurulu olan ve üçlü vardiya sistemiyle çalışan Ankara Fabrikası’nda 2020 yılında toplam 106.255 ton bisküvi ve çikolata üretilmiştir. 2020 yılında tesislerde yapılan operasyonel mükemmellik ve verimlilik çalışmalarıyla toplamda 3.100 ton/yıl kapasite artışı sağlanmıştır.

                                1997 yılında Gebze Organize Sanayi Bölgesi’nde kurulan Gebze Fabrikası, 80 bin m²’si kapalı olmak üzere 85.330 m²’lik bir arazi üzerinde faaliyetlerini sürdürmektedir.
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <div class="text-red bold Truculenta text-32" style="max-width:760px;margin:auto;">
                                Ülker Çikolata, 84.537 m²’lik alan üzerine kurulu toplam
                                29.716 m² kapalı alan üzerinde faaliyetlerini sürdürmektedir.
                            </div>

                            <img src="../assets/retim-kapasite-3.png" class="img-fluid" />

                            <div style="max-width:740px;margin:auto;">
                                <h3 class="text-red bold Truculenta text-37">
                                    Çikolata
                                </h3>
                                Ülker bağlı ortaklığı olan Ülker Çikolata, 84.537 m²’lik
                                alan üzerine kurulu toplam 29.716 m² kapalı alan üzerinde faaliyetlerini sürdürmektedir. Bu fabrikada 2020 yılı içerisinde gerçekleştirilen yeni kalıplama hattı projesiyle 10 bin ton/yıl ek kapasite kazanılmıştır. Tesis Aralık 2020 tarihinde devreye alınmış ve üretime başlamıştır.
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <div class="text-red bold Truculenta text-32" style="max-width:760px;margin:auto;">27 bin m2 kapalı alana sahip Esenyurt Fabrikası’nda, 2020 yılında toplam 25 bin ton kek üretimi yapılmıştır.</div>
                            <img src="../assets/kek.png" class="img-fluid" />
                            <div style="max-width:740px;margin:auto;">
                                <h3 class="text-red bold Truculenta text-37">Kek</h3>
                                Ülker, kek üretimini 1993 yılında kurulan 27 bin m²’lik
                                kapalı alana sahip Esenyurt Fabrikası’nda gerçekleştirmektedir. 2020 yılında da üçlü vardiya düzenine göre çalışan fabrikada 25 bin ton kek üretimi yapılmıştır.

                                Biskot Karaman Fabrikası’nda ise Ekim ayında yapılan verimlilik çalışmasıyla 450 ton/yıl seviyesinde kapasite artışı sağlanmıştır.
                            </div>
                        </div>
                    </slide>

                </carousel>
            </div>
        </div>
        <div v-if="Lang.SelectedLanguage=='en'">
            <div class="" style="margin-top:-100px;">
                <carousel :perPage="perPage" :autoplay="autoplay" :autoplayTimeout="autoplayTimeout" :loop="autoplay" paginationColor="#ffffff" paginationActiveColor="#e91d2d">
                    <slide>
                        <div>
                            <div class="text-red bold Truculenta text-32" style="max-width:760px;margin:auto;">
                                Ülker Bisküvi carries on generating happiness at six domestic factories where new generation digital technologies are in use, with an innovative mind-set focused on consumers with the most productive cutting edge techniques.
                            </div>

                            <img src="../assets/uretim-kapasite.png" class="img-fluid " style="margin-top:20px;margin-bottom:20px;" />
                            <div style="max-width:740px;margin:auto;">
                                <h3 class="text-red bold Truculenta text-37">Biscuit</h3>

                                <p>
                                    Located on a 110 thousand m² land parcel, of which 80 thousand m² is covered space, running three shifts per day, Ankara Factory produced a total of 106,255 tons of biscuits and chocolate in 2020. With the operational excellence and productivity projects carried out at the facilities in 2020, 3,100 tons/year capacity increase was attained in total.
                                    <br /><br />
                                    Established in the Gebze Organized Industrial Zone in 1997, the Gebze Factory is located on an 85,330 m²
                                    land parcel, of which 80 thousand m² is covered space.
                                </p>
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <div class="text-red bold Truculenta text-32" style="max-width:760px;margin:auto;">
                                Ülker Çikolata carries out its operations on 29,716 m2 closed space established on 84,537 m2.
                            </div>

                            <img src="../assets/retim-kapasite-3.png" class="img-fluid" />

                            <div style="max-width:740px;margin:auto;">
                                <h3 class="text-red bold Truculenta text-37">
                                    Chocolate
                                </h3>
                                A subsidiary of Ülker, Ülker Çikolata carries out its operations on 29,716 m2 closed space established on 84,537 m2. With the new moulding line project
                                carried out in 2020 at this factory, 10 thousand
                                tons/year additional capacity is achieved. The facility was taken into operation in December 2020 and production has started.
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <div class="text-red bold Truculenta text-32" style="max-width:760px;margin:auto;">
                                Established on a covered area of 27 thousand m²,
                                Ülker Esenyurt Factory produced 25 thousand tons of cake in 2020.
                            </div>
                            <img src="../assets/kek.png" class="img-fluid" />
                            <div style="max-width:740px;margin:auto;">
                                <h3 class="text-red bold Truculenta text-37">Cake</h3>
                                Ülker produces cakes at its Esenyurt Factory,
                                which was established in 1993 on a covered area of 27 thousand m². In 2020, 25 thousand tons of cake was produced at the factory, which operates in the triple shift order.
                                <br /><br />
                                As a result of the productivity project carried out in October at Biskot Karaman Factory, a capacity increase of 450 tons/year was realized.

                            </div>
                        </div>
                    </slide>

                </carousel>
            </div>
        </div>


        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>
        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/Üretim ve Kapasite.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/Production and Capacity.pdf" class="download">{{Lang.get('download')}}</a>
    </div>
</template>
 
<script>
    import lng from "../store/languages";
    import Slider from "../components/ProductSlider.vue";
    export default {
        name: 'Chairman',
        components: { Slider },
        data() {
            return {
                Lang: lng,
                autoplay: true,
                autoplayTimeout:5000,
                perPage: 1
            }
        }
    }</script>


<style lang="scss">

    .cap .VueCarousel {
        max-height: 100%;
    }

    @media only screen and (max-width: 1024px) {
        .cap .VueCarousel {
            margin-bottom: 100px;
        }

        .cap .VueCarousel-pagination {
            width: 100%;
            text-align: center;
            margin-top: 0 !important;
            z-index: 0;
            position: absolute;
            bottom: 0;
        }
    }

    @media only screen and (min-width: 1024px) {
        .cap .VueCarousel-pagination {
            margin-top: 0px !important;
        }
    }

    .cap
    .VueCarousel-pagination {
        width: 100%;
        text-align: center;
        margin-top: 0 !important;
        z-index: 0;
        position: relative !important;
        bottom: 0;
    }
</style>