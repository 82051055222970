
  <template>
      <div>
          <div class="" style="margin-top:-100px;">
              <carousel :perPage="perPage" paginationColor="#ffffff" paginationActiveColor="#e91d2d">
                  <slide>
                      <div>
                          <div v-html="Lang.get('text7')" class="text-red bold Truculenta text-32" style="max-width:760px;margin:auto;"></div>

                          <img src="../assets/uretim-kapasite.png" class="img-fluid"/>
                          <div style="max-width:760px;margin:auto;">
                              <h3 class="text-red bold Truculenta text-37">Bisküvi</h3>
                              80 bin m²’si kapalı olmak üzere toplam 110 bin m² alan üzerine kurulu olan ve üçlü vardiya sistemiyle çalışan Ankara Fabrikası’nda 2020 yılında toplam 106.255 ton bisküvi ve çikolata üretilmiştir. 2020 yılında tesislerde yapılan operasyonel mükemmellik ve verimlilik çalışmalarıyla toplamda 3.100 ton/yıl kapasite artışı sağlanmıştır.

                              1997 yılında Gebze Organize Sanayi Bölgesi’nde kurulan Gebze Fabrikası, 80 bin m²’si kapalı olmak üzere 85.330 m²’lik bir arazi üzerinde faaliyetlerini sürdürmektedir.
                          </div>
                      </div> 
                  </slide>
                  <slide>
                      <div>
                          <div class="text-red bold Truculenta text-32" style="max-width:760px;margin:auto;">
                              Ülker Çikolata, 84.537 m²’lik alan üzerine kurulu toplam
                              29.716 m² kapalı alan üzerinde faaliyetlerini sürdürmektedir.
                          </div>

                          <img src="../assets/retim-kapasite-3.png" class="img-fluid" />

                          <div style="max-width:760px;margin:auto;">
                              <h3 class="text-red bold Truculenta text-37">
                                  Çikolata
                              </h3>
                              Ülker bağlı ortaklığı olan Ülker Çikolata, 84.537 m²’lik
                              alan üzerine kurulu toplam 29.716 m² kapalı alan üzerinde faaliyetlerini sürdürmektedir. Bu fabrikada 2020 yılı içerisinde gerçekleştirilen yeni kalıplama hattı projesiyle 10 bin ton/yıl ek kapasite kazanılmıştır. Tesis Aralık 2020 tarihinde devreye alınmış ve üretime başlamıştır.
                          </div>
                      </div>
                  </slide>
                  <slide>
                      <div>
                          <div class="text-red bold Truculenta text-32" style="max-width:760px;margin:auto;">27 bin m2 kapalı alana sahip Esenyurt Fabrikası’nda, 2020 yılında toplam 25 bin ton kek üretimi yapılmıştır.</div>
                          <img src="../assets/kek.png" class="img-fluid" />
                          <div style="max-width:760px;margin:auto;">
                              <h3 class="text-red bold Truculenta text-37">Kek</h3>
                              Ülker, kek üretimini 1993 yılında kurulan 27 bin m²’lik
                              kapalı alana sahip Esenyurt Fabrikası’nda gerçekleştirmektedir. 2020 yılında da üçlü vardiya düzenine göre çalışan fabrikada 25 bin ton kek üretimi yapılmıştır.

                              Biskot Karaman Fabrikası’nda ise Ekim ayında yapılan verimlilik çalışmasıyla 450 ton/yıl seviyesinde kapasite artışı sağlanmıştır.
                          </div>
                      </div>
                  </slide>

              </carousel>
          </div>  
      </div>
      
  </template>

<script>
    import lng from "../store/languages";
    export default {
        name: 'Slider',
        data() {
            return {
                Lang: lng,
                perPage: 1,
                slide1active: false,
                slide5active: false,
                slide4active: false,
                slide3active: false,
                slide2active: false
            }
        }
 
}</script>